/*noinspection CssInvalidAtRule*/
@tailwind base;
/*noinspection CssInvalidAtRule*/
@tailwind components;
/*noinspection CssInvalidAtRule*/
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #134c63;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#logo {
  margin-left: -10px;
}

#bulb {
  filter: drop-shadow(2px 2px 3px #444);
  transform: translateX(-15px);
}

#mindmine {
  filter: drop-shadow(6px 6px 4px #102c38);
}